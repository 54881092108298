<template>
  <el-table
    ref="tableListRef"
    v-loading="props.tableLoading"
    :data="props.tableData"
    border
    row-key="id"
    :tree-props="{ children: 'list' }"
    default-expand-all
    class="elv-reconciliation-report-table"
    header-cell-class-name="elv-reconciliation-report-table-header__cell"
    header-row-class-name="elv-reconciliation-report-table-header"
    row-class-name="elv-reconciliation-report-table-row"
    cell-class-name="elv-reconciliation-report-table-row__cell"
    @row-click="onCellClicked"
  >
    <el-table-column
      :label="t('report.category')"
      min-width="461"
      fixed="left"
      class-name="elv-reconciliation-report-table-row__category"
    >
      <template #default="{ row }">
        <span
          v-if="!row?.dataSetNameB && !row?.dataSetNameA && !row?.type && row?.list === undefined"
          class="elv-reconciliation-report-table-row__category__index"
          >{{ row?.index }}</span
        >
        <span class="elv-reconciliation-report-table-row__category__text">{{ categoryName(row) }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="t('common.number')" width="130" align="right" header-align="right">
      <template #default="{ row }">{{
        !row?.dataSetNameB && !row?.dataSetNameA && !row?.type ? formatNumber(row?.reconciliationDataCount) : ''
      }}</template>
    </el-table-column>
    <el-table-column
      v-for="(item, index) in reconciliationStore.reportList?.currencyList"
      :key="index"
      :column-key="item?.symbol"
      :label="item?.showSymbol"
      align="right"
      class-name="elv-reconciliation-report-table-row__number"
      header-align="right"
      min-width="130"
    >
      <template #default="{ row, column }">
        <span
          v-if="!row?.type"
          :class="{
            'is-minus-number': currencyValue(row, column) !== null && new Big(currencyValue(row, column) ?? 0).lt(0)
          }"
          :title="currencyValue(row, column) !== null ? `${formatNumber(currencyValue(row, column), 20)}` : '0'"
        >
          {{
            currencyValue(row, column) !== null
              ? `${formatNumberToSignificantDigits(currencyValue(row, column), 5, '', false)}`
              : '0'
          }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      label=""
      width="48"
      align="center"
      fixed="right"
      class-name="elv-reconciliation-report-table-row__operating"
    >
      <template #default="{ row }">
        <el-tooltip
          v-if="!row?.dataSetNameB && !row?.dataSetNameA && !row?.type && row?.list === undefined"
          effect="dark"
          placement="top"
          popper-class="elv-report-table-tips"
          overlay-classname="elv-report-table-tips"
          :show-after="500"
        >
          <SvgIcon name="arrow-right-line" width="18" height="18" fill="#838D95" @click="onViewDetailedRecords(row)" />
          <template #content>
            <p>{{ t('message.viewDetailedRecords') }}</p>
          </template>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup lang="ts">
import Big from 'big.js'
import { keyBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ReconciliationReportType } from '#/ReconciliationTypes'
import { useTransactionStore } from '@/stores/modules/transactions'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import { formatNumber, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  tableData: {
    type: Array as () => ReconciliationReportType[],
    default: () => []
  },
  tableLoading: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const transactionStore = useTransactionStore()
const reconciliationStore = useReconciliationStore()
const { reconciliationTaskDetail } = storeToRefs(reconciliationStore)

const tableListRef = useTemplateRef('tableListRef')

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const categoryName = (row: any) => {
  if (row?.dataSetNameB || row?.dataSetNameA) {
    return row?.dataSetNameB || row?.dataSetNameA
  }
  if (row.type) {
    switch (row.type) {
      case 'SET_A_UNMATCHED':
        return 'Data set A Unmatched'
      case 'SET_B_UNMATCHED':
        return 'Data set B Unmatched'
      case 'MATCHED':
        return 'Matched but there is a difference in the amount'
      case 'ADJUSTMENT':
        return 'Reconciliation Adjustment Journal'
      default:
        break
    }
    return row.type
  }
  return row?.name
}

const currencyValue = (row: any, column: any) => {
  const currencyMap = keyBy(row?.currencyList, 'underlyingCurrency.symbol')
  const value = currencyMap[column?.columnKey]?.amountTotal ?? null
  return (row?.side === 'B' || row?.parentType?.indexOf('Reconciliation Adjustment Journal') !== -1) && value !== null
    ? Big(value).neg().toString()
    : value
}

const onCellClicked = (row: any) => {
  console.log(row)
}

const onViewDetailedRecords = (row: any) => {
  if (row?.name === 'Pending' && row?.parentType) {
    router.push({
      name: 'entity-reconciliation-details',
      query: {
        type: 'PENDING'
      }
    })
    return
  }
  let side = ''
  if (row?.side && row?.dataSetType) {
    side = row?.side === 'A' ? 'LEFT' : 'RIGHT'
  }
  const filterData: any = {}
  if (
    (row?.parentType?.indexOf('Reconciliation Adjustment Journal') !== -1 ||
      row?.parentType === 'Matched but there is a difference in the amount') &&
    (reconciliationTaskDetail.value.dataSetTypeA === 'LEDGER' ||
      reconciliationTaskDetail.value.dataSetTypeB === 'LEDGER')
  ) {
    filterData.status = row?.parentType === 'Matched but there is a difference in the amount' ? 'MATCHED' : 'ADJUSTMENT'
    side = reconciliationTaskDetail.value.dataSetTypeA === 'LEDGER' ? 'LEFT' : 'RIGHT'
  }

  if (row?.reconciliationReasonId && row?.reconciliationReasonId !== '0') {
    filterData.reconciliationReasonId = row?.reconciliationReasonId
  }

  if (row?.dataSetType === 'TRANSACTION') {
    transactionStore.editEntityFilter(
      entityId.value,
      filterData,
      `RECONCILIATION_PROCESSED_${side}`,
      reconciliationTaskId.value ?? ''
    )
  }

  if (
    row?.dataSetType === 'LEDGER' ||
    row?.parentType === 'Matched but there is a difference in the amount' ||
    row?.parentType?.indexOf('Reconciliation Adjustment Journal') !== -1
  ) {
    transactionStore.editEntityJournalFilter(
      entityId.value,
      filterData,
      `RECONCILIATION_PROCESSED_${side}`,
      reconciliationTaskId.value ?? ''
    )
  }

  router.push({
    name: 'entity-reconciliation-details',
    query: {
      type: 'PROCESSED'
    }
  })
}

const handleArr = (arr: any, isExpand: boolean) => {
  arr.forEach((i: any) => {
    tableListRef.value?.toggleRowExpansion(i, isExpand)
    if (i.list) {
      handleArr(i.list, isExpand)
    }
  })
}

const onCheckTableExpand = (status: string) => {
  nextTick(() => {
    handleArr(props.tableData, status === 'EXPAND')
  })
}

defineExpose({ onCheckTableExpand })
</script>

<style lang="scss">
.elv-reconciliation-report-table {
  --el-table-border-color: #ced7e0;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;

  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  min-height: 20px;

  .el-table__inner-wrapper {
    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-reconciliation-report-table-header {
    background: #eef4fb;

    .elv-reconciliation-report-table-header__cell {
      background: #eef4fb !important;
      height: 36px;
      box-sizing: border-box;
      border-bottom-color: #ced7e0 !important;
      border-right-color: #e4e7eb;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      &.elv-reconciliation-report-table-row__accountId-label {
        .cell {
          padding-left: 0px;
          height: 100%;

          > div {
            width: 40px;
            height: 100%;
            border-right: 1px solid #e4e7eb;
            margin-right: 10px;
          }
        }
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .el-table__row.elv-reconciliation-report-table-row {
    &:last-of-type .elv-reconciliation-report-table-row__cell {
      border-bottom: 0;
    }

    &.el-table__row--level-0 .elv-reconciliation-report-table-row__cell,
    .elv-reconciliation-report-table-row__cell {
      background-color: #fbf9f7;
      border-bottom-color: #ced7e0;
      height: 58px;

      .el-table__expand-icon {
        display: none;
      }

      .cell {
        height: 100%;
        display: flex;
        align-items: center;

        [text] {
          padding-left: 0px;
        }
      }

      &.elv-reconciliation-report-table-row__category {
        .cell {
          font-size: 13px;
          font-weight: 600;
          color: #0e0f11;
          word-break: break-word;
        }
      }
    }

    &.el-table__row--level-0 .elv-reconciliation-report-table-row__cell {
      &.elv-reconciliation-report-table-row__category .cell {
        padding-left: 36px;

        span {
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 10px;
          border-left: 1px solid #edf0f3;
        }
      }

      &.elv-reconciliation-report-table-row__number {
        .cell {
          color: #0e0f11;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          .is-minus-number {
            color: #1753eb;
          }
        }
      }
    }

    &.el-table__row--level-1 .elv-reconciliation-report-table-row__cell {
      background-color: #fafcff;
      height: 42px;

      .el-table__expand-icon {
        display: block;
        width: 36px;
        margin-right: 0;
      }

      &.elv-reconciliation-report-table-row__category {
        .cell {
          padding-left: 0;
          font-size: 13px;
          font-weight: 600;

          .el-table__indent {
            padding-left: 0px !important;
            border: 0;
          }

          .el-table__placeholder {
            border-left: 0;
            padding-left: 0;
            width: 36px;
          }

          span {
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            border-left: 1px solid #edf0f3;
          }
        }
      }
    }

    &.el-table__row--level-2 .elv-reconciliation-report-table-row__cell {
      background-color: #fff;
      height: 42px;

      &.elv-reconciliation-report-table-row__category {
        .cell {
          font-size: 13px;
          font-weight: 400;
          padding-left: 0;

          .el-table__indent,
          .el-table__placeholder {
            display: none;
          }

          .elv-reconciliation-report-table-row__category__index {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 10px;
            width: 60px;
            box-sizing: border-box;
            color: #aaafb6;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .elv-reconciliation-report-table-row__category__text {
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            border-left: 1px solid #edf0f3;
          }
        }
      }

      &.elv-reconciliation-report-table-row__number .cell {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .is-minus-number {
          color: #1753eb;
        }
      }
    }
  }

  .elv-reconciliation-report-table-row__cell {
    padding: 0;
    min-height: 42px;

    &:last-child {
      border-right: 0px;
    }

    &.is-center {
      .cell {
        justify-content: center;
      }
    }

    &.is-right .cell {
      justify-content: flex-end;
    }

    &:not(.el-table__row--level-0, .el-table__row--level-1, .el-table__row--level-2) {
      &.elv-reconciliation-report-table-row__number .cell {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .is-minus-number {
          color: #1753eb;
        }
      }

      .elv-reconciliation-report-table-row__category__index {
        display: none;
      }

      &.elv-reconciliation-report-table-row__category .cell {
        padding-left: 36px;

        .el-table__placeholder {
          display: none;
        }

        .elv-reconciliation-report-table-row__category__text {
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 10px;
          border-left: 1px solid #edf0f3;
        }
      }
    }

    &.elv-reconciliation-report-table-row__accountId {
      height: 42px;

      .cell {
        display: flex;
        padding-left: 0px;
        box-sizing: border-box;
        align-items: center;
        height: 100%;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .el-table__indent {
          padding-left: 0px !important;
          border-left: 0px;
        }

        .el-table__placeholder {
          width: 40px;
          box-sizing: border-box;
          height: 100%;
          padding-left: 40px !important;
          border-left: 0px;
        }

        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #edf0f3;
          white-space: nowrap;
        }

        .el-table__expand-icon {
          width: 40px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          margin-right: 0px;
        }
      }
    }

    &.elv-reconciliation-report-table-row__category {
      .cell {
        font-weight: 500;
      }
    }

    &.elv-reconciliation-report-table-row__operating {
      .cell {
        display: flex;
        gap: 8px;

        svg {
          fill: #838d95;

          &.is-disabled {
            cursor: not-allowed !important;
          }

          &:not(.is-disabled):hover {
            cursor: pointer;
            fill: #1e2024;
          }
        }
      }
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    .elv-reconciliation-report-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      height: 42px;

      .cell.elv-reconciliation-report-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-reconciliation-report-table-row__amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }
}

.elv-chartOfAccount-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
