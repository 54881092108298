<template>
  <div class="elv-reconciliation-report-container">
    <ExportDataTaskHistoryInfo
      :exportType="exportProgressStatusType.reconciliationReport"
      :isMarginTop="true"
      :isMarginBottom="false"
    />
    <div class="elv-reconciliation-report-header">
      <div class="elv-reconciliation-report-header__title">
        <p>{{ t('reconciliation.report') }}</p>
        <p>{{ t('reconciliation.reportForThisReconciliationTask') }}</p>
      </div>

      <elv-export-button
        :params="{
          type: exportProgressStatusType.reconciliationReport.value,
          extra: {
            type: 'REPORT',
            reconciliationTaskId: reconciliationTaskId
          }
        }"
        textColor="#1E2024"
        :noPermission="false"
      />
    </div>
    <div class="elv-reconciliation-report-table-content">
      <ListTable ref="listTableRef" :tableLoading="loading" :tableData="reconciliationStore.reportList.list" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { emitter } from '@/lib/mitt'
import ListTable from './components/ListTable.vue'
import { exportProgressStatusType } from '@/config/global'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'

const { t } = useI18n()
const route = useRoute()
const reconciliationStore = useReconciliationStore()

const listTableRef = useTemplateRef('listTableRef')
const loading = ref(false)

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

/**
 * @description: 获取对账报告列表
 */
const getReconciliationReportList = async () => {
  try {
    loading.value = true
    await reconciliationStore.fetchReconciliationReportList(entityId.value, reconciliationTaskId.value)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

emitter.on('getReconciliationReportList', getReconciliationReportList)

watch(
  () => route,
  async (newValue) => {
    if (newValue.name === 'entity-reconciliation-report') {
      getReconciliationReportList()
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  reconciliationStore.reportList = {
    currencyList: [],
    list: []
  }
  emitter.off('getReconciliationReportList', getReconciliationReportList)
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-report-container {
  width: 100%;
  height: calc(100% - 128px);
  padding: 0px 26px 0px 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-common-export-task-history-info-list {
    padding: 0px;
  }

  .elv-reconciliation-report-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    font-family: 'Plus Jakarta Sans';
    position: relative;

    .elv-reconciliation-report-header__title {
      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        color: #838d95;

        &:first-child {
          font-size: 18px;
          font-weight: 700;
          line-height: 23px;
          color: #0e0f11;
          margin-bottom: 4px;
        }
      }
    }

    :deep(.el-button.elv-base-button-export) {
      position: absolute;
      right: 0px;
      bottom: 16px;
    }
  }

  .elv-reconciliation-report-table-content {
    flex: 1;
    min-height: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
</style>
